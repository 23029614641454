.card-width {
  margin-right: 9px;
  width: 100% !important;
}
  
.plan-card-width {
  margin-right: 9px;
  width: 280px !important;
}

.text-bold-blue {
  font-weight: 500 !important;
  line-height: 21px !important;
  color: $primary-blue !important;
  font-size: 13px !important;
}

.text-size {
  font-size: 13px !important;
}

.row-border {
  border-bottom: 1px solid $dark-gray-600 !important;
}

.goal-badges {
  background-color: $light-blue-250;
  color: $primary-blue;
  font-weight: 500;
  padding: 4px 10px;
  border-radius: 5px;
}

.height-goal {
  min-height: 280px !important;
}

.image-background {
  background-image: url('../../../utils/images/goalImage.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.fund-image-backgound {
  background-image: url('../../../utils/images/usd.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 150px;
}

.blue-confirm-btn {
  border-radius: 3px;
  background-color: $primary-blue;
  color: $primary-white;
}

.grey-confirm-btn {
  border-radius: 3px;
  background-color: $gray-100;
  color: $primary-white;
}

.withdrw-modal-err{
  color: $primary-red;
  text-align: center;
}

.light-blue-cancel-btn {
  border-radius: 3px;
  background-color: $light-blue-450;
  color: $primary-blue;
}

.tr-color {
  color: $primary-blue;
}

.blue-separator {
  border-bottom: 0.496191px solid $light-blue-450;
  }
  
  .investment-summary {
      border: 1px solid $light-blue-450 !important
  }
  
  .label-styles {
    color: $dark-gray-600;
}

.goal-image-container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 228px;
  border-radius: 16px 16px 0px 0px;
}

@media screen and (max-width: 400px) {
  .goals-container {
    grid-template-columns: repeat(auto-fit, 100%);
  }
}

@media screen and (max-width: 480px) {
  .card-width {
    width: 100% !important;
  }
}

@media screen and (max-width: 688px) {
  .card-width {
    width: 100% !important;
  }
}

@media screen and (max-width: 1046px) {
  .resize {
    padding: 10px 10px !important;
    font-size: 12px !important;
  }
}