@import './variables';

// LAYOUT
@import './_metronic/layout/components/aside/index.scss';
@import './_metronic/layout/components/footer/index.scss';
@import './_metronic/layout/components/index.scss';
@import './_metronic/_assets/sass/pages/login/classic/login-1.scss';
@import './_metronic/_assets/sass/components/wave';

// DASHBOARD
@import './app/modules/Common/buttons/index.scss';
@import './app/modules/Dashboard/pages/dashboardCard.scss';


// INSTRUCTIONS PAGE
@import './app//modules/Instructions/Pages/index.scss';
@import './app/modules/Common/Goal/index.scss';

// COMPONENTS
@import './app/modules/Common/SmallCircle/index.scss';
@import './app/modules/Common/sliderRanger/index.scss';
@import './app/modules/Common/Button/index.scss';
@import './app/modules/Common/PageNumbers/index.scss';
@import './app//modules/Common/CustomCheckBox/index.scss';
@import './app//modules/Common/Input/input.scss';
@import './app//modules/Common/GoalIcon/index.scss';

// GOAL
@import './app/modules/Goal/pages/index.scss';
@import './app/modules/Goal/components/GoalSummaryItem/index.scss';

// PORTFOLIO
@import './app/modules/Portfolio/components/SingleGoal/index.scss';
@import './app/modules/Portfolio/components/TargetAmountProgressBar/index.scss';
@import './app/modules//Portfolio/pages/index.scss';

// REMINDER
@import './app/modules/Reminder/index.scss';


//******** TEXT COLORS *********

.red-text {
    color: $primary-red;
}

.white-text {
    color: $primary-white;
}

.green-text-600 {
    color: $green-600;
}

.blue-text {
    color: $primary-blue;
}

.text-black-600 {
    color: $black-400;
}

//******** DIVIDERS *********

.gray-divider {
    border-bottom: 1px solid $gray-600;
}

.dark-gray-divider {
    border-bottom: 1px solid $dark-gray-600;
}

//******** FONT SIZES *********

.font-size-11 {
    font-size: 11px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-13 {
    font-size: 13px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-17 {
    font-size: 17px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-25 {
    font-size: 25px;
}

.font-size-30 {
    font-size: 30px;
}

.font-size-33 {
    font-size: 33px;
}

.font-size-50 {
    font-size: 50px
}

//******** FONT WEIGHT *********

.font-weight-bold-900 {
    font-weight: 900;
}

//******** BACKGROUND COLORS *********

.bg-red {
    background-color: $primary-red;
}

.bg-red-100 {
    background-color: $red-100;
}

.bg-red-400 {
    background-color: $red-400;
}

.bg-light-blue {
    background-color: $light-blue-100;
}

.bg-light-blue-250 {
    background-color: $light-blue-250;
}

.bg-white {
    background-color: $primary-white;
}

.bg-gray-100 {
    background-color: $dark-gray-300;
}

.bg-black-600 {
    background-color: $black-600;
}

.bg-green-400 {
    background-color: $green-400;
}

//******** BORDER RADIUS *********

.border-radius-9 {
    border-radius: 9px;
}

.border-radius-12 {
    border-radius: 12px;
}

.border-radius-16 {
    border-radius: 16px;
}

.border-radius-25 {
    border-radius: 25px
};

.border-radius-full {
    border-radius: 50%;
}

.border-radius-bottom-16 {
    border-radius: 0px 0px 16px 16px;
}

.border-radius-top-16 {
    border-radius: 16px 16px 0px 0px;
}

//******** Z-INDEX *********

.z-index-8 {
    z-index: 8;
}

.z-index-1 {
    z-index: 1;
}

//******** SPACING *********

.margin-left--15 {
    margin-left: -15px !important;
}

.margin-top--5 {
    margin-top: -5px !important;
}

.border-light-blue-1 {
    border: 1px solid $light-blue-100;
}
