.goal-icon-container {
    width: 60px;
    height: auto;
    background: $gray-600;
    padding: 8px;
    border-radius: 50%;
}

.goal-icon {
    width: 100%;
    height: auto;
}